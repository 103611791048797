import React from 'react';
import PropTypes from 'prop-types';
import { Blank } from 'grommet-icons';

const IconClock = (props) => {
  return (
    <Blank
      viewBox="0 0 37 37"
      aria-hidden="true"
      focusable="false"
      role="presentation"
      {...props}
      fill="none"
    >
      <g
        transform="translate(.824 .884)"
        fillRule="nonzero"
        strokeWidth="2"
        fill="none"
        stroke={props.color}
      >
        <circle cx="18" cy="18" r="17" fill="none" stroke={props.color} />
        <path
          fill="none"
          stroke={props.color}
          strokeLinecap="square"
          d="M10.278 15.003l7.355 6.532L27.676 12"
        />
      </g>
    </Blank>
  );
};

IconClock.propTypes = {
  color: PropTypes.string,
};

export default IconClock;
